
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
//images



//pages
import Home from './pages/home';



function App() {
  return (
    <div className="App">
    <Router>
    <div>
      {/* Define Routes */}
      <Routes>
        <Route path="/" element={<Home />} />
        
      </Routes>
    </div>
  </Router>
    </div>
  );
}

export default App;


  
  


