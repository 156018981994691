import React from 'react';
import './home.css';

//cmp



//img
import Logo1 from '../images/Swathi_group_1-removebg-preview.png';
import Swathii from '../images/SwathiiCE.png';
import swaasso from '../images/swaasso.png';
import swacorp from '../images/swacorp.png';


//logo's

import Linkedin from '../images/In-Blue-96@2x.png';
import Instagram from '../images/Instagram_Glyph_Gradient.png';
import X from '../images/logo-black.png';


function home() {
  return (
    <div className='homePage'>

    <div className='header'>
    <img src={Logo1} className='SwaGrp' />
    </div>

    <div className='businessGrp'>
    <div className='swaconst1'>
    <img src={Swathii} className='swacc' />
    <p>Turnkey Construction Projects</p>
    <p>Interior & Exterior Service</p>
    <p>Construction Consultation (Free)*</p>
    <p>Residential & Commercial</p>
    <p>(For more project information,<br/>please contact us)<br/> <br/>Ph: +91 94453 60482</p>
    <div className='socialIcons1'>
    <a href='https://www.linkedin.com/company/swathigroupcc/' target='_blank'><img src={Linkedin} className='socialLogo' /></a>
    <a href='https://x.com/weswathii' target='_blank'><img src={X} className='socialLogo' /></a>
    <a href='https://www.instagram.com/weswathii/' target='_blank'><img src={Instagram} className='socialLogo' /></a>
    </div>


    </div>
    
    <div className='swaconst2'>
    <img src={swaasso} className='swacc' />
    <p>Architectural Design</p>
    <p>Interior Design</p>
    <p>3D Visualization</p>
    <p>Project Management</p>
    <p>Residential & Commercial</p>
    <div className='socialIcons2'>
    <a href='https://www.linkedin.com/company/swathiassociates/' target='_blank'><img src={Linkedin} className='socialLogo' /></a>
    <a href='https://x.com/swathidesign' target='_blank'><img src={X} className='socialLogo' /></a>
    <a href='https://www.instagram.com/swathiassociates/' target='_blank'><img src={Instagram} className='socialLogo' /></a>
    </div>

    </div>
    
    <div className='swaconst3'>
    <img src={swacorp} className='swacc' />
    <p>M&A Transaction Service</p>
    <p>Strategic Investment Advisory</p>
    <p>Due Diligence & Valuation</p>
    <p>Fast track Merger and De-merger</p>
    <p>Fund Raising*</p>
    <p>(For more information,<br/>please contact us)<br/> <br/>Ph: +91 94449 34381</p>
    <div className='socialIcons3'>
    <a href='https://www.linkedin.com/company/swathicorporate/' target='_blank'><img src={Linkedin} className='socialLogo' /></a>
    <a href='https://x.com/swathicorporate' target='_blank'><img src={X} className='socialLogo' /></a>
    
    </div>

    </div>
    
    </div>
    
    </div>
  )
}

export default home